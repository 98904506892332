<template>
    <div>
        <v-toolbar style="background:#cad9e5;">
            <v-row>
                <v-btn
                    tile
                    color=""
                    style="margin-right:1%;"
                    @click="getDatas"
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    검색
                </v-btn>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;"
                >
                    <v-icon left>
                        mdi-plus-circle
                    </v-icon>
                    추가
                </v-btn>
                <v-btn
                    tile
                    color="success"
                    style="margin-right:1%;"
                >
                    <v-icon left>
                        mdi-pencil
                    </v-icon>
                    수정
                </v-btn>
                <v-btn
                    tile
                    color="error"
                    style="margin-right:1%;"
                >
                    <v-icon left>
                        mdi-delete-circle
                    </v-icon>
                    삭제
                </v-btn>
            </v-row>
        </v-toolbar>
        <v-toolbar style="background:#cad9e5;">
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="wearing-item_code"
                    label="품번"
                    @change="value => onTextChangne('item_code', value)"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="wearing-item_name"
                    label="품명"
                    @change="value => onTextChangne('item_name', value)"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="wearing-bin_code"
                    label="위치정보"
                    @change="value => onTextChangne('bin_code', value)"
                ></v-text-field>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page="100"
            class="elevation-1"
            show-select
            item-key="id"
            @click:row="onClickRow"
            height="100%"
        >
            
        </v-data-table>
    </div>
</template>

<script>

export default {
  name: "Wearing",
  components: {
    
  },
  data() {
    return {
        headers: [
            { value:'item_code', text:'품번', width:'15%', align:'start', sortable: true, },
            { value:'item_name', text:'품명', width:'15%', align:'start', sortable: true, },
            { value:'specification', text:'규격', width:'25%', align:'start', sortable: true, },
            { value:'quan', text:'수량', width:'10%', align:'start', sortable: true, },
            { value:'bin_code', text:'위치정보', width:'10%', align:'start', sortable: true, },
            { value:'last_in_date', text:'최근입고날짜', width:'25%', align:'start', sortable: true, },
        ],
        datas:[
            { id:1, item_code:'품번1', item_name:'품명1', specification:'규격1', quan:2, bin_code:'A012311', last_in_date:'2021-11-12' },
            { id:2, item_code:'품번2', item_name:'품명2', specification:'규격2', quan:3, bin_code:'A012312', last_in_date:'2021-11-13' },
            { id:3, item_code:'품번3', item_name:'품명3', specification:'규격3', quan:4, bin_code:'A012313', last_in_date:'2021-11-14' },
            { id:4, item_code:'품번4', item_name:'품명4', specification:'규격4', quan:5, bin_code:'A012314', last_in_date:'2021-11-15' },
            { id:5, item_code:'품번1', item_name:'품명1', specification:'규격1', quan:2, bin_code:'A012311', last_in_date:'2021-11-16' },
            { id:6, item_code:'품번4', item_name:'품명4', specification:'규격4', quan:5, bin_code:'A012314', last_in_date:'2021-11-15' },
            { id:7, item_code:'품번1', item_name:'품명1', specification:'규격1', quan:2, bin_code:'A012311', last_in_date:'2021-11-16' },
            { id:8, item_code:'품번4', item_name:'품명4', specification:'규격4', quan:5, bin_code:'A012314', last_in_date:'2021-11-15' },
            { id:9, item_code:'품번1', item_name:'품명1', specification:'규격1', quan:2, bin_code:'A012311', last_in_date:'2021-11-16' },
            { id:10, item_code:'품번3', item_name:'품명3', specification:'규격3', quan:4, bin_code:'A012313', last_in_date:'2021-11-14' },
            { id:11, item_code:'품번4', item_name:'품명4', specification:'규격4', quan:5, bin_code:'A012314', last_in_date:'2021-11-15' },
            { id:12, item_code:'품번1', item_name:'품명1', specification:'규격1', quan:2, bin_code:'A012311', last_in_date:'2021-11-16' }
        ],
    };
  },
  mounted() {
    console.log('===???', this.$route)
  },
  watch: {
   
  },
  methods: {
    getDatas() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationHistory';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          gubun:'IN',
          apiType:'ALL'
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.datas;
              this.datas = datas;
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialog = false;
          })
    },
    onClickRow(record, row) {
        const isSelected = row.isSelected;
        if(isSelected) {
            row.select(false);
        } else {
            row.select(true);
        }
    },

    onTextChangne(key, val) {
        console.log(' ')
    }
  }
};
</script>

<style>
.v-data-table__wrapper {
    height:72vh !important;
}
</style>